import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import LoginPicture from "../../assets/login_picture.png"
import { faFacebook, faGoogle } from "@fortawesome/free-brands-svg-icons"
import { useState } from "react"

export default function ForgotPassword() {
    const [data, setData] = useState({
        email: "",
        password: ""
    })

    const handleChange = (e: any) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
    }

    return (
        <div className="min-h-screen w-screen flex justify-center items-center">
            <div className="w-full md:w-1/3 h-fit px-4 py-2">
                <img src={LoginPicture} alt="Login" className="mx-auto" />
                <div className="w-full h-fit px-4 py-2 border border-black">
                    <div className="w-full h-16 flex justify-center items-center">
                        <h1 className="text-2xl font-bold">Login</h1>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="w-full h-16 flex justify-center items-center relative">
                            <FontAwesomeIcon icon="at" className="absolute left-2 top-0 bottom-0 m-auto text-black" />
                            <input value={data.email} onChange={handleChange} name="email" type="text" placeholder="Email" className="w-full h-10 border border-black rounded pl-8" />
                        </div>
                        <div className="w-full h-16 flex justify-center items-center relative">
                            <FontAwesomeIcon icon="lock" className="absolute left-2 top-0 bottom-0 m-auto text-black" />
                            <input value={data.password} onChange={handleChange} name="password" type="password" placeholder="Password" className="w-full h-10 border border-black rounded pl-8" />
                        </div>
                        <hr className="border-dashed border-black mt-2 mb-3" />
                        <a href="/forgot-password" className="text-black hover:text-gray-700 transition-all text-xs text-center w-full flex mt-2 justify-center items-center">Forgot password?</a>
                        <div className="w-full h-16 flex justify-center items-center">
                            <button type="submit" className="w-full rounded h-10 uppercase bg-black text-white font-bold hover:rounded-lg hover:text-slate-500 transition-all">Login</button>
                        </div>
                    </form>
                    <hr className="border-dashed border-black mt-2 mb-3" />
                    <div className="w-2/3 mx-auto flex justify-around items-center">
                        <a href="/auth/facebook" className="text-black transition-all flex bg-white justify-center items-center w-12 h-12 border border-black rounded hover:rounded-lg hover:text-blue-500">
                            <FontAwesomeIcon icon={faFacebook} />
                        </a>
                        <a href="/auth/google" className="text-black transition-all flex bg-white justify-center items-center w-12 h-12 border border-black rounded hover:rounded-lg hover:text-red-500">
                            <FontAwesomeIcon icon={faGoogle} />
                        </a>
                    </div>
                    <hr className="border-dashed border-black mt-3 mb-3" />
                    <span className="text-black hover:text-gray-700 transition-all text-xs text-center w-full flex mt-2 justify-center items-center cursor-pointer">Don't have an account yet?</span>
                    <div className="w-full h-16 flex justify-center items-center">
                        <a href="/signup" className="w-full rounded h-10 uppercase border border-black text-black font-bold flex items-center justify-center bg-white hover:rounded-lg hover:text-stone-500 transition-all">SIGN UP</a>
                    </div>
                </div>
            </div>
        </div>
    )
}