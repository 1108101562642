import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Navbar } from "@material-tailwind/react";
import React from "react";

interface TopProps {
    handleOpen: () => void;
}

export default function Top({ handleOpen }: Readonly<TopProps>): React.JSX.Element {
    return (
        <Navbar onPointerEnterCapture={() => console.log()} onPointerLeaveCapture={() => console.log()} placeholder="" color="white" className={`rounded-sm transition-all top-4 z-30 py-3 border-2 border-black`} fullWidth blurred>
            <div className="flex justify-between flex-row items-center">
                <IconButton onPointerEnterCapture={() => console.log()} onPointerLeaveCapture={() => console.log()} placeholder="" variant="text" color="red" className="grid xl:hidden" onClick={() => handleOpen()}>
                    <FontAwesomeIcon icon="bars" strokeWidth={3} className="h-6 w-6 text-purple-700" />
                </IconButton>
                <IconButton onPointerEnterCapture={() => console.log()} onPointerLeaveCapture={() => console.log()}
                    placeholder=""
                    variant="text"
                    color="red"
                    onClick={() => {
                        localStorage.clear();
                        window.location.href = "/";
                    }}
                >
                    <FontAwesomeIcon icon="sign-out" className="h-5 w-5 text-purple-700" />
                </IconButton>
            </div>
        </Navbar>
    );
}
