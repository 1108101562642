import { Dialog } from "@headlessui/react";
import { useEffect, useState } from "react";
import { PublicityInterface } from "../../interfaces/PublicityInterface";
import PubsAPI from "../../services/API/clients/PubsAPI";
import ModalWrapper from "./ModalWrapper";
import handleErrorResponse from "../../hooks/HandleErrors";

export default function ModalPub({ open, setOpen, idToEdit }: Readonly<{ open: boolean; setOpen: (open: boolean) => void; idToEdit?: string }>) {
    const [titleHaveError, setTitleHaveError] = useState<boolean>(true);
    const [imgHaveError, setImgHaveError] = useState<boolean>(true);
    const [oldDatas, setOldDatas] = useState<PublicityInterface>({
        id: "",
        title: "",
        description: "",
        urlImg: "",
    });

    const fetchData = async () => {
        if (!idToEdit || idToEdit === "") return;
        console.log(idToEdit)
        const response = await PubsAPI.pub(idToEdit);
        if (response.status === 200 || response.status === 201) {
            setOldDatas(response.body);
        } else {
            handleErrorResponse(response);
        }
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setImgHaveError(true);
        if (idToEdit && idToEdit !== "") {
            const datas = {
                id: idToEdit,
                title: oldDatas.title,
                description: oldDatas.description,
                urlImg: oldDatas.urlImg,
            }
            const response = await PubsAPI.edit(datas);
            if (response.status === 200 || response.status === 201) {
                setOpen(false);
                clearDatas();
            } else {
                handleErrorResponse(response);
                if (response?.body?.messages?.title)
                    setTitleHaveError(false);
                if (response?.body?.messages?.urlImg)
                    setImgHaveError(false);
            }
        } else {
            handleCreate();
        }
    };

    const handleCreate = async () => {
        const datas = {
            title: oldDatas.title,
            description: oldDatas.description,
            urlImg: oldDatas.urlImg,
        }
        const response = await PubsAPI.new(datas);
        if (response.status === 200 || response.status === 201) {
            setOpen(false);
            clearDatas();
        } else {
            handleErrorResponse(response);
            if (response?.body?.messages?.title)
                setTitleHaveError(false);
            if (response?.body?.messages?.urlImg)
                setImgHaveError(false);
        }
    }

    const clearDatas = () => {
        setOldDatas({
            id: "",
            title: "",
            description: "",
            urlImg: "",
        });
    }

    useEffect(() => {
        if (idToEdit || idToEdit === "") {
            fetchData();
        }
    }, [idToEdit]);

    const deleteMyObject = async () => {
        if (idToEdit && idToEdit !== "") {
            const response = await PubsAPI.delete(idToEdit);
            if (response.status === 200 || response.status === 201) {
                setOpen(false);
            } else {
                handleErrorResponse(response);
            }
        }
    }

    return (
        <ModalWrapper open={open} setOpen={setOpen}>
            <Dialog.Panel className="transform overflow-hidden rounded-2xl bg-white px-16 py-12 text-left align-middle shadow-xl transition-all max-w-3xl">
                <Dialog.Title as="h3" className="text-3xl font-medium leading-6 text-gray-900 text-center">
                    {idToEdit ? "Modification d'une" : "Nouvelle"} publicité
                </Dialog.Title>
                <form onSubmit={handleSubmit} className="flex flex-col gap-4 mt-6">
                    <input
                        type="text"
                        placeholder="Nom"
                        className={`border rounded-lg p-2 ${titleHaveError ? "" : "border-red-500"}`}
                        value={oldDatas?.title}
                        onChange={(e) => setOldDatas({ ...oldDatas, title: e.target.value })}
                    />
                    <input
                        type="text"
                        placeholder="Description"
                        className={`border rounded-lg p-2`}
                        value={oldDatas?.description}
                        onChange={(e) => setOldDatas({ ...oldDatas, description: e.target.value })}
                    />
                    <input
                        type="text"
                        placeholder="URL Image"
                        className={`border rounded-lg p-2 ${imgHaveError ? "" : "border-red-500"}`}
                        value={oldDatas?.urlImg}
                        onChange={(e) => setOldDatas({ ...oldDatas, urlImg: e.target.value })}
                    />
                    <div className="flex justify-between items-center">
                        <button type="submit" className="rounded-full border bg-white px-4 py-2 text-sm font-medium  hover:shadow-xl transition-all font-sans">
                            {idToEdit ? "Modifer" : "Ajouter"}
                        </button>
                        {idToEdit && (
                            <button type="button" onClick={() => deleteMyObject()} className="rounded-full border bg-red-300 px-4 py-2 text-sm font-medium  hover:shadow-xl transition-all font-sans">
                                Supprimer
                            </button>
                        )}
                    </div>
                </form>
            </Dialog.Panel>
        </ModalWrapper>
    );
}
