import { Outlet } from "react-router-dom";
import Aside from "./Aside";
import { useState } from "react";
import Top from "./Top";

export default function Dash() {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(!open);
    };

    return (
        <div className="h-screen bg-blue-gray-50/50 overflow-x-hidden">
            <div className="p-4 xl:ml-80">
                <Top handleOpen={handleOpen} />
                <Aside open={open} handleOpen={handleOpen} />
                <Outlet />
            </div>
        </div>
    )
}