import { Dialog } from "@headlessui/react";
import ModalWrapper from "./ModalWrapper";
import Fidelite from "../../services/API/clients/FideliteAPI";
import { ChangeEvent, useEffect, useLayoutEffect, useState } from "react";
import { CreateFideliteInterface } from "../../interfaces/CreateFideliteInterface";
import { ProductsInterface } from "../../interfaces/ProductsInterface";
import ProductsAPI from "../../services/API/clients/ProductsAPI";
import handleErrorResponse from "../../hooks/HandleErrors";

export default function ModalFidelity({ open, setOpen, idToEdit }: Readonly<{ open: boolean; setOpen: (open: boolean) => void; idToEdit?: string }>) {
    const [pointsHaveError, setPointsHaveError] = useState<boolean>(true);
    const [productHaveError, setProductHaveError] = useState<boolean>(true);
    const [oldDatas, setOldDatas] = useState<CreateFideliteInterface>({
        id: "",
        productId: "",
        magasin: "",
        point: 0,
    });
    const [products, setProducts] = useState<ProductsInterface[]>([])
    const [selectedProduct, setSelectedProduct] = useState<ProductsInterface>()

    const fetchProducts = async () => {
        const productsFound = await ProductsAPI.products();

        setProducts(productsFound.body);

        if (productsFound.body.length > 0) {
            setSelectedProduct(productsFound.body[0]);
            setOldDatas({...oldDatas, productId: productsFound.body[0].id});
        }
    } 

    const fetchData = async () => {
        if (!idToEdit || idToEdit === "") return;
        const response = await Fidelite.offer(idToEdit);
        if (response.status === 200 || response.status === 201) {
            setOldDatas(response.body);
        } else {
            handleErrorResponse(response);
        }
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setPointsHaveError(true);
        setProductHaveError(true);
        if (idToEdit && idToEdit !== "") {
            const response = await Fidelite.edit({
                id: oldDatas.id,
                productId: oldDatas.productId,
                point: oldDatas.point
            });
            if (response.status === 200 || response.status === 201) {
                setOpen(false);
                clearDatas();
            } else {
                handleErrorResponse(response);
                if (response?.body?.messages?.point)
                    setPointsHaveError(false);
                if (response.body.title === "Bad Request")
                    setProductHaveError(false);
            }
        } else {
            handleCreate();
        }
    };

    const handleCreate = async () => {
        const response = await Fidelite.new({
            productId: oldDatas.productId,
            point: oldDatas.point
        });
        if (response.status === 200 || response.status === 201) {
            setOpen(false);
            clearDatas();
        } else {
            handleErrorResponse(response);
            if (response?.body?.messages?.point)
                setPointsHaveError(false);
            if (response.body.title === "Bad Request")
                setProductHaveError(false);
        }
    }

    const clearDatas = () => {
        setOldDatas({
            id: "",
            productId: "",
            magasin: "",
            point: 0
        });
    }

    const handleSelectedProduct = (event: ChangeEvent<HTMLSelectElement>) => {
        if (event.target.value === undefined) {
            return;
        }

        const productFound = products.find((product) => product.id === event.target.value);

        if (!productFound) {
            return;
        }

        setSelectedProduct(productFound);
        setOldDatas({...oldDatas, productId: productFound.id});
    }

    useEffect(() => {
        if (idToEdit || idToEdit === "") {
            fetchData();
        }
    }, [idToEdit]);

    useLayoutEffect(() => {
        fetchProducts();
    }, [])

    const deleteFidelity = async () => {
        const response = await Fidelite.delete(oldDatas.id);
        if (response.status === 200 || response.status === 201) {
            setOpen(false);
            clearDatas();
        } else {
            handleErrorResponse(response);
        }
    }

    return (
        <ModalWrapper open={open} setOpen={setOpen}>
            <Dialog.Panel className="transform overflow-hidden rounded-2xl bg-white px-16 py-12 text-left align-middle shadow-xl transition-all max-w-3xl">
                <Dialog.Title as="h3" className="text-3xl font-medium leading-6 text-gray-900 text-center">
                    {idToEdit ? "Modification d'une" : "Nouvelle"} promotion
                </Dialog.Title>
                <form onSubmit={handleSubmit} className="flex flex-col gap-4 mt-6">
                    {products.length === 0 
                        ? (
                            <div className="relative items-center w-full text-base font-regular px-4 py-4 rounded-lg bg-gradient-to-tr from-blue-gray-600 to-blue-gray-400 text-white flex">
                                Aucun produit trouvé
                            </div>
                        ) : 
                        (
                            <>
                                <select 
                                    value={selectedProduct?.id} 
                                    onChange={handleSelectedProduct}
                                    className={`border rounded-lg p-2 ${productHaveError ? "" : "border-red-500"}`}
                                >
                                    {products.map((option) => (
                                        <option key={option.id} value={option.id}>
                                            {option.name}
                                        </option>
                                    ))}
                                </select>
                                <input
                                    type="number"
                                    placeholder="Points"
                                    className={`border rounded-lg p-2 ${pointsHaveError ? "" : "border-red-500"}`}
                                    value={oldDatas?.point}
                                    onChange={(e) => setOldDatas({ ...oldDatas, point: parseInt(e.target.value) })}
                                />
                                <div className="flex justify-between">
                                    <button type="submit" className="rounded-full border bg-white px-4 py-2 text-sm font-medium  hover:shadow-xl transition-all font-sans">
                                        {idToEdit ? "modifier" : "ajouter"}
                                    </button>
                                    {idToEdit && (
                                        <button onClick={() => deleteFidelity()} className="rounded-full border bg-red-300 text-white px-4 py-2 text-sm font-medium  hover:shadow-xl transition-all font-sans">
                                            Supprimer
                                        </button>
                                    )}
                                </div>
                            </>
                        )
                    }
                </form>
            </Dialog.Panel>
        </ModalWrapper>
    );
}
