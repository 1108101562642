import APIClient from "../APIClients";

export default class Client {
    public static async user() {
        return APIClient.interceptor.get(`/shop`);
    }

    public static async edit(data: {
        email: string;
        password: string;
        name: string;
        address: string;
        phoneNumber: string;
        palierFidelity: number;
        pointPerPalier: number;
    }) {
        return APIClient.interceptor.put(`/shop`, data);
    }

    public static async deleting() {
        return APIClient.interceptor.delete(`/shop`);
    }
}
