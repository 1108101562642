import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import RegisterPicture from "../../assets/register_picture.png";
import Auth from "../../services/API/clients/AuthAPI";

import BorderBottom from "../../assets/bottomBorderWhite.png";
import handleErrorResponse from "../../hooks/HandleErrors";
import ReactGoogleAutocomplete from "react-google-autocomplete";

export default function Register() {
    const navigate = useNavigate();
    const [data, setData] = useState<{
        email: string;
        password: string;
        name: string;
        address: string;
        phoneNumber: string;
        latitude: number;
        longitude: number;
        palierFidelite: number;
        pointPerPalier: number;
        confirmPassword: string;
    }>({
        email: "",
        name: "",
        address: "",
        phoneNumber: "",
        latitude: 0,
        longitude: 0,
        palierFidelite: 0,
        pointPerPalier: 0,
        password: "",
        confirmPassword: "",
    });
    const [emailIsValid, setEmailIsValid] = useState(true);
    const [passwordIsValid, setPasswordIsValid] = useState(true);
    const [isVisible, setIsVisible] = useState({
        password: false,
        confirmPassword: false,
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setEmailIsValid(true);
        setPasswordIsValid(true);

        if (data.password !== data.confirmPassword) {
            toast.warn("Les deux mots de passes sont différents");
            setPasswordIsValid(false);
            return;
        }

        const register = () => {
            navigator.geolocation.getCurrentPosition(async (location) => {
                const datas = {
                    email: data.email,
                    password: data.password,
                    name: data.name,
                    address: data.address,
                    phoneNumber: data.phoneNumber,
                    latitude: location.coords.latitude,
                    longitude: location.coords.longitude,
                    palierFidelite: data.palierFidelite,
                    pointPerPalier: data.pointPerPalier,
                };
                const response = await Auth.register(datas);
                if (response.status === 200) {
                    toast.success("Account created successfully");
                    navigate("/");
                } else {
                    handleErrorResponse(response);
                    if (response.body.message === "Shop Auth already exists") {
                        setEmailIsValid(false);
                    }
                }
            }, () => {
                toast.warn("Please enable your location and refresh the page");
            });
        };

        register();
    };

    return (
        <div className="min-h-screen w-screen flex justify-center items-center">
            <div className="w-full md:w-1/3 h-fit px-4 py-2">
                <img src={RegisterPicture} alt="Login" className="mx-auto" />
                <div className="w-full h-fit px-4 py-2 border-[3px] bg-white border-black">
                    <div className="w-full h-16 flex justify-center items-center">
                        <h1 className="text-2xl font-bold">Inscription</h1>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="w-full h-16 flex justify-center items-center relative">
                            <FontAwesomeIcon icon="user" className="absolute left-2 top-0 bottom-0 m-auto text-black" />
                            <input
                                value={data.name}
                                onChange={handleChange}
                                name="name"
                                type="text"
                                placeholder="Nom"
                                className="w-full h-10 border border-black rounded pl-8"
                                required
                            />
                        </div>
                        <div className="w-full h-16 flex justify-center items-center relative">
                            <FontAwesomeIcon icon="at" className="absolute left-2 top-0 bottom-0 m-auto text-black" />
                            <input
                                value={data.email}
                                onChange={handleChange}
                                name="email"
                                type="text"
                                placeholder="Adresse email"
                                className={`w-full h-10 border border-black rounded pl-8 ${emailIsValid ? "" : "border-red-500"}`}
                                required
                            />
                        </div>
                        <div className="w-full h-16 flex justify-center items-center relative">
                            <FontAwesomeIcon icon="phone" className="absolute left-2 top-0 bottom-0 m-auto text-black" />
                            <input
                                value={data.phoneNumber}
                                onChange={handleChange}
                                name="phoneNumber"
                                type="text"
                                placeholder="Numéro de téléphone"
                                className={`w-full h-10 border border-black rounded pl-8 ${emailIsValid ? "" : "border-red-500"}`}
                                required
                            />
                        </div>
                        <div className="w-full h-16 flex justify-center items-center relative">
                            <FontAwesomeIcon icon="location-dot" className="absolute left-2 top-0 bottom-0 m-auto text-black" />
                            <ReactGoogleAutocomplete
                                apiKey="AIzaSyBwHnMrttRQUb-cg3ebVVOfnOmimj_UAsQ"
                                onPlaceSelected={(place) => setData({ ...data, address: place.formatted_address })}
                                inputAutocompleteValue={data.address}
                                defaultValue={data.address}
                                options={{
                                    types: ["address"],
                                    componentRestrictions: { country: "fr" },
                                }}
                                className="w-full h-10 border border-black rounded pl-8"
                            />
                        </div>
                        <div className="w-full h-16 flex justify-center items-center relative">
                            <FontAwesomeIcon icon="ticket" className="absolute left-2 top-0 bottom-0 m-auto text-black" />
                            <input
                                value={data.palierFidelite}
                                onChange={handleChange}
                                name="palierFidelite"
                                type="number"
                                placeholder="Paliers de fidélité"
                                className="w-full h-10 border border-black rounded pl-8"
                                required
                            />
                        </div>
                        <div className="w-full h-16 flex justify-center items-center relative">
                            <FontAwesomeIcon icon="ticket" className="absolute left-2 top-0 bottom-0 m-auto text-black" />
                            <input
                                value={data.pointPerPalier}
                                onChange={handleChange}
                                name="pointPerPalier"
                                type="number"
                                placeholder="Point par palier"
                                className="w-full h-10 border border-black rounded pl-8"
                                required
                            />
                        </div>
                        <div className="w-full h-16 flex justify-center items-center relative">
                            <FontAwesomeIcon icon="lock" className="absolute left-2 top-0 bottom-0 m-auto text-black" />
                            <input
                                value={data.password}
                                onChange={handleChange}
                                name="password"
                                type={isVisible.password ? "text" : "password"}
                                placeholder="Mot de passe"
                                className={`w-full h-10 border border-black rounded pl-8 ${passwordIsValid ? "" : "border-red-500"}`}
                                required
                            />
                            <FontAwesomeIcon
                                icon={isVisible.password ? "eye-slash" : "eye"}
                                onClick={() => setIsVisible({ ...isVisible, password: !isVisible.password })}
                                className="absolute right-2 top-0 bottom-0 m-auto text-black cursor-pointer hover:text-purple-500"
                            />
                        </div>
                        <div className="w-full h-16 flex justify-center items-center relative">
                            <FontAwesomeIcon icon="lock" className="absolute left-2 top-0 bottom-0 m-auto text-black" />
                            <input
                                value={data.confirmPassword}
                                onChange={handleChange}
                                name="confirmPassword"
                                type={isVisible.confirmPassword ? "text" : "password"}
                                placeholder="Confirmer le mot de passe"
                                className={`w-full h-10 border border-black rounded pl-8 ${passwordIsValid ? "" : "border-red-500"}`}
                                required
                            />
                            <FontAwesomeIcon
                                icon={isVisible.confirmPassword ? "eye-slash" : "eye"}
                                onClick={() => setIsVisible({ ...isVisible, confirmPassword: !isVisible.confirmPassword })}
                                className="absolute right-2 top-0 bottom-0 m-auto text-black cursor-pointer hover:text-purple-500"
                            />
                        </div>
                        <div className="w-full h-16 flex justify-center items-center">
                            <button
                                type="submit"
                                className="w-full rounded h-10 uppercase bg-black text-white font-bold hover:rounded-lg hover:text-purple-500 transition-all"
                            >
                                Inscription
                            </button>
                        </div>
                    </form>
                    <hr className="border-dashed border-black mt-3 mb-3" />
                    <span className="text-black hover:text-gray-700 transition-all text-xs text-center w-full flex mt-2 justify-center items-center cursor-pointer">
                        Vous avez déjà un compte ? 
                    </span>
                    <div className="w-full h-16 flex justify-center items-center">
                        <Link
                            to="/"
                            className="w-full rounded h-10 uppercase border border-black text-black font-bold flex items-center justify-center bg-white hover:rounded-lg hover:text-purple-500 transition-all"
                        >
                            CONNEXION
                        </Link>
                    </div>
                </div>
                <div className="w-full h-4 flex justify-center items-center">
                    <img src={BorderBottom} alt="Border Bottom" />
                </div>
            </div>
        </div>
    );
}
