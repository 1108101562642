import APIClient from "../APIClients";

export default class Fidelite {
    public static async offers() {
        return APIClient.interceptor.get(`/fidelity/offer`);
    }

    public static async edit(data: { id: string; productId: string; point: number }) {
        return APIClient.interceptor.put(`/fidelity/offer`, data);
    }

    public static async new(data: { productId: string; point: number }) {
        return APIClient.interceptor.post(`/fidelity/offer`, data);
    }

    public static async delete(id: string) {
        return APIClient.interceptor.delete(`/fidelity/offer/${id}`);
    }

    public static async shopName(shopName: string) {
        return APIClient.interceptor.get(`/fidelity/shopAccount/${shopName}`);
    }

    public static async shopAccount() {
        return APIClient.interceptor.get(`/fidelity/shopAccount`);
    }

    public static async shopOffer(shopName: string) {
        return APIClient.interceptor.get(`/fidelity/shop/offer/${shopName}`);
    }

    public static async offer(id: string) {
        return APIClient.interceptor.get(`/fidelity/offer/${id}`);
    }
}
