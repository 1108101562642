import { Button, Card, CardBody, CardFooter, CardHeader, Input, Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { UserInterface } from "../../../interfaces/UserInterface";
import Client from "../../../services/API/clients/ClientAPI";
import { toast } from "react-toastify";
import { Storage } from "../../../services/storage";
import AsyncSelect from "react-select/async";
import handleErrorResponse from "../../../hooks/HandleErrors";
import ReactGoogleAutocomplete from "react-google-autocomplete";

export default function Profil() {
  const [actualUser, setActualUser] = useState<UserInterface>({
    email: "",
    password: "",
    name: "",
    address: "",
    phoneNumber: "",
    latitude: 0,
    longitude: 0,
    palierFidelity: 0,
    pointPerPalier: 0,
  });
  const [newPassword, setNewPassword] = useState("");

  const handleChange = (e: any) => {
    setActualUser({
      ...actualUser,
      [e.target.name]: e.target.value,
    });
  };

  const handleEditUser = async () => {
    const datas = {
      email: actualUser.email,
      password: actualUser.password,
      name: actualUser.name,
      address: actualUser.address,
      phoneNumber: actualUser.phoneNumber,
      latitude: actualUser.latitude,
      longitude: actualUser.longitude,
      palierFidelity: actualUser.palierFidelity,
      pointPerPalier: actualUser.pointPerPalier,
      reductionOfferByPoint: [],
    };
    const response = await Client.edit(datas);
    if (response.status === 200 || response.status === 201) {
      toast.success("User edited successfully");
    } else {
      handleErrorResponse(response);
    }
  };

  useEffect(() => {
    const fetchDataUser = async () => {
      const response = await Client.user();
      if (response.status === 200 || response.status === 201) {
        setActualUser(response.body);
      } else {
        handleErrorResponse(response);
      }
    };
    fetchDataUser();
  }, []);

  const handleDeleteUser = async () => {
    const response = await Client.deleting();
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      Storage.emptyStorage();
      toast.success("User deleted successfully");
    } else {
      handleErrorResponse(response);
    }
  };

  const promiseOptions = async (inputValue: string) => {
    const response = await fetch(`https://api-adresse.data.gouv.fr/search/?q=${inputValue}&limit=5`);
    const data = await response.json();
    return data.features.map((feature: any) => {
      return {
        label: feature.properties.label,
        value: feature.geometry.coordinates,
      };
    });
  };

  return (
    <Card onPointerEnterCapture={() => console.log()} onPointerLeaveCapture={() => console.log()} placeholder="" className="w-full mt-12 shadow-none border-2 border-black rounded-sm">
      <CardHeader onPointerEnterCapture={() => console.log()} onPointerLeaveCapture={() => console.log()} placeholder="" variant="gradient" color="purple" className="flex justify-between items-center px-8 h-16 place-items-center rounded-sm">
        <span></span>
        <Typography onPointerEnterCapture={() => console.log()} onPointerLeaveCapture={() => console.log()} placeholder="" variant="h3" color="white">
          Profil
        </Typography>
        <span></span>
      </CardHeader>
      <CardBody onPointerEnterCapture={() => console.log()} onPointerLeaveCapture={() => console.log()} placeholder="" className="flex flex-col gap-4">
        <Input onPointerEnterCapture={() => console.log()} onPointerLeaveCapture={() => console.log()} value={actualUser.email} onChange={handleChange} name="email" crossOrigin="" color="purple" label="Email" size="lg" disabled />
        <Input onPointerEnterCapture={() => console.log()} onPointerLeaveCapture={() => console.log()} value={actualUser.name} onChange={handleChange} name="name" crossOrigin="" color="purple" label="Name" size="lg" />
        {/* <Input onPointerEnterCapture={() => console.log()} onPointerLeaveCapture={() => console.log()} value={actualUser.address} onChange={handleChange} name="address" crossOrigin="" color="purple" label="Address" size="lg" /> */}
        <label className="text-xs font-regular text-blue-gray-400 ml-2.5 px-1 w-fit -mt-2 -mb-6 bg-white relative z-10">Adresse</label>
        <ReactGoogleAutocomplete
          apiKey="AIzaSyBwHnMrttRQUb-cg3ebVVOfnOmimj_UAsQ"
          onPlaceSelected={(place) => setActualUser({ ...actualUser, address: place.formatted_address })}
          inputAutocompleteValue={actualUser.address}
          defaultValue={actualUser.address}
          options={{
            types: ["address"],
            componentRestrictions: { country: "fr" },
          }}
          className="peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 disabled:cursor-not-allowed transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 placeholder:opacity-0 focus:placeholder:opacity-100 text-sm px-3 py-3 rounded-md border-blue-gray-200 focus:border-purple-500"
        />
        <Input onPointerEnterCapture={() => console.log()} onPointerLeaveCapture={() => console.log()} value={actualUser.phoneNumber} onChange={handleChange} name="phoneNumber" crossOrigin="" color="purple" label="Phone Number" size="lg" />
        <Input onPointerEnterCapture={() => console.log()} onPointerLeaveCapture={() => console.log()} value={actualUser.palierFidelity} onChange={handleChange} name="palierFidelity" crossOrigin="" color="purple" label="Palier Fidelite" size="lg" />
        <Input onPointerEnterCapture={() => console.log()} onPointerLeaveCapture={() => console.log()} value={actualUser.pointPerPalier} onChange={handleChange} name="pointPerPalier" crossOrigin="" color="purple" label="Point Per Palier" size="lg" />
        <Input type="password" onPointerEnterCapture={() => console.log()} onPointerLeaveCapture={() => console.log()} value={actualUser.password} onChange={handleChange} name="password" crossOrigin="" color="purple" label="Password" size="lg" />
        <Input type="password" onPointerEnterCapture={() => console.log()} onPointerLeaveCapture={() => console.log()} value={newPassword} onChange={(e) => setNewPassword(e.target.value)} crossOrigin="" color="purple" label="New password" size="lg" />
      </CardBody>
      <CardFooter onPointerEnterCapture={() => console.log()} onPointerLeaveCapture={() => console.log()} placeholder="" className="pt-0 flex flex-col gap-4">
        <Button onPointerEnterCapture={() => console.log()} onPointerLeaveCapture={() => console.log()} onClick={() => handleEditUser()} placeholder="" color="purple" variant="outlined" className="rounded-sm" fullWidth>
          Save modifications
        </Button>
        <Button onPointerEnterCapture={() => console.log()} onPointerLeaveCapture={() => console.log()} onClick={() => handleDeleteUser()} placeholder="" color="red" variant="outlined" className="rounded-sm" fullWidth>
          Delete account
        </Button>
      </CardFooter>
    </Card>
  );
}
