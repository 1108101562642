import APIClient from "../APIClients";

export default class Auth {
    public static async logout(data: {}) {
        return APIClient.noInterceptor.post("/auth/logout", data);
    }

    public static async login(data: { email: string; password: string }) {
        return APIClient.noInterceptor.post("/auth/login", data);
    }

    public static async register(data: {
        email: string;
        password: string;
        name: string;
        address: string;
        phoneNumber: string;
        latitude: number,
        longitude: number,
        palierFidelite: number;
        pointPerPalier: number;
    }) {
        return APIClient.noInterceptor.post("/shop/register", data);
    }
}
