import APIClient from "../APIClients";

export default class StatisticsAPI {
    public static async new(data: {}, ageDebut: string, ageFin: string, productId?: string) {
        // const random = Math.floor(Math.random() * 50);
        // return { status: 200, body: { visiteCount: random } };

        return APIClient.interceptor.post(
            `/statistic/product${
                productId && 
                productId !== undefined &&
                productId !== "" ? 
                    `?productId=${productId}&ageDebut=${ageDebut}&ageFin=${ageFin}` :
                    `?ageDebut=${ageDebut}&ageFin=${ageFin}`}`, data);
    }
}
