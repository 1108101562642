import React from "react";
import ReactDOM from "react-dom/client";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Storage } from "./services/storage";
import Register from "./pages/Auth/Register";
import Login from "./pages/Auth/Login";
import Page404 from "./pages/Bundle/Page404";
import Dash from "./pages/Dash/General/Dash";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import Product from "./pages/Dash/FirstPart/Product";
import Statistics from "./pages/Dash/FirstPart/Statistics";
import Promotion from "./pages/Dash/FirstPart/Promotion";
import Profil from "./pages/Dash/SecondPart/Profil";
import Publicity from "./pages/Dash/FirstPart/Publicity";

library.add(fas);

const token = Storage.getToken();

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/signup" element={<Register />} />
                <Route path="/dash" element={token ? <Dash /> : <Navigate to="/" />}>
                    <Route path="products" element={<Product />} />
                    <Route path="statistics" element={<Statistics />} />
                    <Route path="promotion" element={<Promotion />} />
                    <Route path="publicity" element={<Publicity />} />
                    <Route path="profil" element={<Profil />} />
                </Route>
                <Route path="/shop" element={<Page404 />} />
            </Routes>
        </BrowserRouter>
        <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
        />
    </React.StrictMode>
);
