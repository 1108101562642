import { Button, Typography } from "@material-tailwind/react";
import React, { ReactNode } from "react";
import { NavLink } from "react-router-dom";

interface NavLinksProps {
    layout?: string;
    path: string;
    handleOpen: () => void;
    icon: ReactNode;
    name: string;
}

const NavLinks: React.FC<NavLinksProps> = ({ layout, path, handleOpen, icon, name }) => {
    return (
        <NavLink
            to={`/${layout}${path}`}
            onClick={() => {
                handleOpen();
            }}
        >
            {({ isActive }) => {
                return (
                    <Button onPointerEnterCapture={() => console.log()} onPointerLeaveCapture={() => console.log()}
                        placeholder=""
                        variant={isActive ? "gradient" : "text"}
                        color={isActive ? "purple" : "blue-gray"}
                        className={`flex items-center gap-6 px-4 capitalize`}
                        fullWidth
                    >
                        <div className="flex gap-1 items-center">
                            {icon}{" "}
                        </div>
                        <Typography onPointerEnterCapture={() => console.log()} onPointerLeaveCapture={() => console.log()} placeholder="" color="inherit" className="font-medium capitalize">
                            {name}
                        </Typography>
                    </Button>
                );
            }}
        </NavLink>
    );
};

export default NavLinks;
