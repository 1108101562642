import { Card, CardBody, CardHeader, IconButton, Typography } from "@material-tailwind/react";
import { ProductsInterface } from "../../../interfaces/ProductsInterface";
import { useEffect, useState } from "react";
import ProductsAPI from "../../../services/API/clients/ProductsAPI";
import handleErrorResponse from "../../../hooks/HandleErrors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import StatisticsAPI from "../../../services/API/clients/StatisticAPI";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import LineChart from "../../../components/ChartLines";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);
  

export default function Statistics() {
    const [products, setProducts] = useState<ProductsInterface[]>([]);
    const [selectedProduct, setSelectedProduct] = useState<ProductsInterface>();
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");
    const [statistics, setStatistics] = useState<number[]>([]);
    const [labels, setLabels] = useState<string[]>([]);

    const fetchProducts = async () => {
        const response = await ProductsAPI.products();
        if (response.status === 200 || response.status === 201) {
            setProducts(response.body);
            setSelectedProduct(response.body[0]);
        } else {
            handleErrorResponse(response);
        }
    }

    const fetchStatistics = async (isRandom = false) => {
        const startMoment = moment(startDate, 'YYYY-MM-DD');
        const endMoment = moment(endDate, 'YYYY-MM-DD');
    
        const startYear = startMoment.year();
        const startMonth = startMoment.month();
        const endYear = endMoment.year();
        const endMonth = endMoment.month();
    
        const results: any[] = [];
    
        for (let year = startYear; year <= endYear; year++) {
            const start = (year === startYear) ? startMonth : 0;
            const end = (year === endYear) ? endMonth : 11;
    
            for (let month = start; month <= end; month++) {
                const startDate2 = moment([year, month]).startOf('month').toISOString();
                const endDate2 = moment([year, month]).endOf('month').toISOString();
    
                const data = {
                    startDate: startDate2,
                    endDate: endDate2,
                    searchByLabel: true,
                    periodType: "DAY"
                };
                
                if (isRandom) {
                    results.push(Math.floor(Math.random() * 50));
                } else {
                    try {
                        const response = await StatisticsAPI.new(data, "0", "99", selectedProduct?.id);
                        results.push(response.body.visiteCount);
                    } catch (error) {
                        console.error(`Error fetching statistics for month ${month + 1} of ${year}:`, error);
                    }
                }
            }
        }
    
        const monthLabels: string[] = [];
        for (let year = startYear; year <= endYear; year++) {
            const start = (year === startYear) ? startMonth : 0;
            const end = (year === endYear) ? endMonth : 11;
    
            for (let month = start; month <= end; month++) {
                monthLabels.push(moment([year, month]).format('MMMM YYYY'));
            }
        }
    
        setLabels(monthLabels);
        setStatistics(results);
    };

    useEffect(() => {
        setStartDate(moment().startOf('year').format('YYYY-MM-DD'));
        setEndDate(moment().endOf('month').format('YYYY-MM-DD'));
        fetchProducts();
        fetchStatistics();
    }, []);

    const handleSelectedProduct = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const product = products.find((product) => product.id === e.target.value);
        setSelectedProduct(product);
    }

    return (
        <Card onPointerEnterCapture={() => console.log()} onPointerLeaveCapture={() => console.log()} placeholder="" className="w-full mt-12 shadow-none border-2 border-black rounded-sm">
            <CardHeader onPointerEnterCapture={() => console.log()} onPointerLeaveCapture={() => console.log()} placeholder="" variant="gradient" color="purple" className="flex justify-center items-center px-8 h-16 place-items-center rounded-sm">
                <Typography onPointerEnterCapture={() => console.log()} onPointerLeaveCapture={() => console.log()} placeholder="" variant="h3" color="white">
                Statistiques
                </Typography>
            </CardHeader>
            <CardBody onPointerEnterCapture={() => console.log()} onPointerLeaveCapture={() => console.log()} placeholder="" className="flex flex-col gap-4">
                <div className="flex justify-between items-center gap-4">
                    <select 
                        value={selectedProduct?.id} 
                        onChange={handleSelectedProduct}
                        className={`border rounded-lg p-2 w-full disabled:bg-gray-300`}
                        disabled={products.length === 0}
                    >
                        {products.map((option) => (
                            <option key={option.id} value={option.id}>
                                {option.name}
                            </option>
                        ))}
                    </select>
                    <input
                        type="date"
                        placeholder="Points"
                        className={`border rounded-lg p-2 w-full`}
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                    />
                    <input
                        type="date"
                        placeholder="Points"
                        className={`border rounded-lg p-2 w-full`}
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                    />
                    <IconButton
                        color="purple"
                        onClick={() => fetchStatistics()}
                        className="text-white w-32"
                        placeholder=""
                        onPointerEnterCapture={() => console.log()}
                        onPointerLeaveCapture={() => console.log()}
                    >   
                        <FontAwesomeIcon icon="magnifying-glass" />
                    </IconButton>
                    <IconButton
                        color="purple"
                        onClick={() => fetchStatistics(true)}
                        className="text-white w-32"
                        placeholder=""
                        onPointerEnterCapture={() => console.log()}
                        onPointerLeaveCapture={() => console.log()}
                    >   
                        <FontAwesomeIcon icon="random" />
                    </IconButton>
                </div>
                <LineChart data={statistics} labels={labels} />
            </CardBody>
        </Card>
    );
}
