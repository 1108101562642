import APIClient from "../APIClients";

export default class PubsAPI {
    public static async pubs() {
        return APIClient.interceptor.get(`/publicity`);
    }

    public static async pub(id: string) {
        return APIClient.interceptor.get(`/publicity/${id}`);
    }

    public static async edit(
        data: {
            id: string,
            title: string,
            description: string,
            urlImg: string
        }
    ) {
        return APIClient.interceptor.put(`/publicity`, data);
    }

    public static async new(
        data: {
            title: string,
            description: string,
            urlImg: string
        }
    ) {
        return APIClient.interceptor.post(`/publicity`, data);
    }

    public static async delete(id: string) {
        return APIClient.interceptor.delete(`/publicity/${id}`);
    }
}
