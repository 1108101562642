import { Line } from "react-chartjs-2";

interface LineChartProps {
    data: number[];
    labels: string[];
  }
  
  const LineChart: React.FC<LineChartProps> = ({ data, labels }) => {
    const chartData = {
      labels: labels, // Labels pour les axes x
      datasets: [
        {
          label: 'My Dataset', // Légende du dataset
          data: data, // Données à afficher
          fill: false,
          backgroundColor: 'rgba(75,192,192,0.2)',
          borderColor: 'rgba(75,192,192,1)',
          tension: 0.4, // Courbe lissée
        },
      ],
    };
  
    const options = {
      responsive: true,
      plugins: {
        legend: {
          display: true,
          position: 'top' as const,
        },
        title: {
          display: true,
          text: 'Line Chart Example',
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: 'Time',
          },
        },
        y: {
          title: {
            display: true,
            text: 'Value',
          },
        },
      },
    };
  
    return <Line data={chartData} options={options} />;
  };
  
  export default LineChart;
  