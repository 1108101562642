import { toast } from 'react-toastify';

// enum BadRequestErrorType {
//   WRONG_FIELD_FORMAT = 'WRONG_FIELD_FORMAT',
//   CONFLICTING_DATA = 'CONFLICTING_DATA',
// }

enum ForbiddenErrorType {
  WRONG_PERMISSION = 'WRONG_PERMISSION',
}

enum NotFoundErrorType {
  RESOURCE_NOT_FOUND = 'RESOURCE_NOT_FOUND',
}

enum UnauthorizedErrorType {
  UNAUTHORIZED = 'UNAUTHORIZED',
}

interface ErrorResponse {
  message?: string;
  messages?: { [key: string]: string };
  type?: string;
  multiple?: boolean;
}

const handleErrorResponse = (error: { status: number, body: ErrorResponse}) => {
    if (!error) {
        toast.error('An unexpected error occurred');
        return;
    }
    const { status, body } = error;
    const errorResponse: ErrorResponse = body || {};

    switch (status) {
        case 400:
            if (errorResponse.multiple && errorResponse.messages) {
            Object.entries(errorResponse.messages).forEach(([field, message]) => {
                toast.warning(`${field}: ${message}`);
            });
            } else {
                toast.warning(errorResponse.message || 'Bad Request');
            }
            break;
        case 401:
            toast.error(errorResponse.message || UnauthorizedErrorType.UNAUTHORIZED);
            break;
        case 403:
            toast.error(errorResponse.message || ForbiddenErrorType.WRONG_PERMISSION);
            break;
        case 404:
            toast.error(errorResponse.message || NotFoundErrorType.RESOURCE_NOT_FOUND);
            break;
        default:
            toast.error('An unexpected error occurred');
            break;
    }
};

export default handleErrorResponse;
